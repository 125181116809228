import * as React from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import * as style from './news.module.css'

const News = () => {
  return (
    <Layout pageTitle="Spiked">
      <Helmet>
        <meta
          name="description"
          content="Links to news articles about Spiked Film"
        />
        <title>News | Spiked Film</title>
        <body className="bg-img news-bg-img" />
      </Helmet>
      <div className={style.newsContainer}>
        <div>
          <a
            href="https://deadline.com/2021/02/gravitas-ventures-spiked-1234690758/?fbclid=IwAR3gb1ASW_6VtHECW4snFGkOug_Ff5ezztOehYP3ae_3LmyRuygpc1RrEgU"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              alt="Deadline Article 1 of 3"
              src="../images/news/deadline1.PNG"
            />
          </a>
        </div>
        <div>
          <a
            href="https://deadline.com/2019/08/elementary-aidan-quinn-spiked-movie-juan-martinez-1202701693/"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              alt="Deadline Article 2 of 3"
              src="../images/news/deadline2.PNG"
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.dcourier.com/news/2019/sep/07/movie-inspired-local-newspaper-owners-near-fatal-o/"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              alt="Deadline Article 1 of 3"
              src="../images/news/dcourier.PNG"
            />
          </a>
        </div>
        <div>
          <a
            href="https://deadline.com/2019/09/eric-roberts-my-dinner-with-eric-roberts-julias-brother-wendy-makkena-walter-belenky-spiked-charming-the-hearts-of-men-tina-ivlev-curtis-hamilton-1202708252/"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              alt="Deadline Article 2 of 3"
              src="../images/news/deadline3.PNG"
            />
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default News
